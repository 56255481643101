import React, { Fragment, useEffect, useState } from "react"

import Loadable from "@loadable/component"

import { Text, Div, Anchor, Button, Icon } from "atomize"

import { makeStyles } from "@material-ui/core/styles"

// import CircularProgress from "@material-ui/core/CircularProgress"

import ReactWebChat, {
  createStore,
  createDirectLine,
} from "botframework-webchat"

import "./chat.min.css"

const Dialog = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/Dialog")
)
const DialogTitle = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/DialogTitle")
)
const DialogContent = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/DialogContent")
)
const Tooltip = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/Tooltip")
)
const IconButton = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/IconButton")
)
const Close = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/icons/Close")
)

const useStyles = makeStyles(() => ({
  modalHeader: {
    borderBottom: "none",
    paddingRight: "24px",
    paddingBottom: "12px",
    paddingLeft: "24px",
    "@media (max-width: 768px)": {
      paddingTop: "12px",
      paddingRight: "12px",
    },
    "@media (min-width: 769px)": {
      paddingTop: "14px",
    },
    minHeight: "16.43px",
  },
  modalTitle: {
    // margin: "0",
    // lineHeight: "1.42857143",
  },
  modalCloseButton: {
    color: "#999999",
    "@media (max-width: 768px)": {
      marginTop: "auto",
    },
    // marginTop: '-6px',
    WebkitAppearance: "none",
    padding: "0 !important",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right",
  },
  modalClose: {
    "@media (max-width: 768px)": {
      width: "30px",
      height: "30px",
    },
    "@media (min-width: 769px)": {
      width: "24px",
      height: "24px",
    },
  },
  modalBody: {
    backgroundColor: "white",
    height: "100%",
    paddingTop: "0px !important",
    position: "relative",
    "@media (max-width: 768px)": {
      paddingRight: "0px !important",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
      height: "100%",
    },
    "@media (min-width: 769px)": {
      paddingRight: "24px",
      paddingLeft: "24px",
      paddingBottom: "16px",
    },
  },
  modalChat: {
    borderRadius: "8px",
    height: "100%",
    "@media (max-width: 768px)": {
      width: "100% !important",
      margin: "0px !important",
      maxHeight: "100% !important",
      height: "100% !important",
    },
    "@media (max-height: 750px)": {
      margin: "0px !important",
      maxHeight: "100% !important",
      height: "100% !important",
    },
  },
}))

const styleOptions = {
  backgroundColor: "white",
  userAvatarInitials: false,
  botAvatarInitials: true,
  botAvatarBackgroundColor: "#eee",
  botAvatarImage: "/avatar.svg",
  hideSendBox: true,
  hideUploadButton: true,
  primaryFont:
    '"SF Pro Text",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif',
  bubbleFromUserTextColor: "white",
  bubbleTextColor: "#293754",
  bubbleFromUserBackground: "#394d75",
  paddingRegular: 0,
  paddingWide: 0,
  hideScrollToEndButton: true,
  typingAnimationDuration: 60000,
}

export default function NichtVerfuegbar(props) {

  const classes = useStyles()

  // _____ RENDER _____

  return (
    <Dialog
      classes={{
        paper: classes.modalChat,
      }}
      fullWidth={true}
      maxWidth={"md"}
      keepMounted
      open={
        typeof props.open !== "undefined" && props.open !== null
          ? props.open
          : false
      }
      onClose={() => {
        if (props.onClose) {
          props.onClose(false)
        }
      }}
      aria-labelledby="corona-navi-chat-title"
      id="webchat"
    >
      <Tooltip title="Chat schließen">
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Schließen"
            color="inherit"
            onClick={() => {
              if (props.onClose) {
                props.onClose(false)
              }
            }}
          >
            <Close className={classes.modalClose} />
          </IconButton>
        </Tooltip>
      <DialogTitle
        id="corona-navi-chat-title"
        disableTypography
        className={classes.modalHeader}
        style={{ backgroundColor: "white" }}
      >
        <Text tag="h2" textSize="subheader" textColor="medium">
          Corona Navi
        </Text>
      </DialogTitle>
      <DialogContent id="convaise-webchat-v3" className={classes.modalBody}>
        <Text tag="h3" textSize="heading">
          Dieser Assistent steht im Augenblick nicht mehr zur Verfügung.
        </Text>
      </DialogContent>
    </Dialog>
  )
}
